import { connect } from 'react-redux'
import { compose } from 'recompose'
import withAuthorization from '../Session/withAuthorization'
import {
  setUser,
  addProjectContract,
  setProjectContracts,
} from '../../reducers/user'
import { db } from '../../firebase'
import AccountPage from './AccountContainer'

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUser: () => {
    const { uid } = ownProps.authUser
    db.getUser(uid).on('value', (snapshot) => {
      dispatch(setUser(snapshot.val()))
    })
  },
  fetchProjectContracts: (selectedProject) => {
    const { uid } = ownProps.authUser
    db
      .getProjectContracts(uid, selectedProject.id)
      .on('value', (snapshot) => {
        dispatch(setProjectContracts(snapshot.val()))
      })
  },
  updateAccount: (user) => {
    const { uid } = ownProps.authUser

    db.updateUserAccount(uid, user)

    dispatch(setUser(user))
  },
  addProjectContract: (projectId, contract) => {
    const { uid } = ownProps.authUser
    db.addProjectContract(uid, projectId, contract)

    dispatch(addProjectContract(contract))
  },
})

const mapStateToProps = (state) => ({
  uid: state.sessionState.authUser.uid,
  user: state.userState.user,
  projectContracts: state.userState.projectContracts,
})

const authCondition = (authUser) => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountPage)
