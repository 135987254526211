import React from 'react'

const LandingPage = () => (
  <div className="landing-page">
    <div className="header">
      <div className="app-content">
        <h1 className="title">
          Home
        </h1>
        <p>
          The Landing Page is open to everyone, even though the user isnt signed in.
        </p>
      </div>
    </div>
    <div className="app-content content">
        Easy:Time is a time management tool to simplify your life as a single
        owner of a company or just helping you manage your hours...
    </div>
  </div>
)

export default LandingPage
