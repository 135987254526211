import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withAuthorization from '../Session/withAuthorization'

const Statistics = () => (
  <div>
    Project statistics
  </div>
)

const mapStateToProps = (state) => ({
  projects: state.userState.projects,
  timesheet: state.userState.timesheet,
  selectedMonth: state.userState.selectedMonth,
  user: state.userState.user,
})

const authCondition = (authUser) => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
)(Statistics)
