import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { db } from '../../firebase'

import './edit-project.css'

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

class EditProjectForm extends Component {
  constructor(props) {
    super(props)

    const { name, rate, active } = this.props
    this.state = { name, rate, active }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()

    const { uid, projectId, createdAt } = this.props
    const { name, rate, active } = this.state

    db.editProject({
      uid, name, rate, active, createdAt, projectId,
    })
  }

  render() {
    const {
      name, rate, active,
    } = this.state
    const isInvalid = name === '' || rate === ''

    return (
      <div className="edit-project">
        <form onSubmit={this.onSubmit}>
          <label htmlFor="name">
            Project Name
            <input
              id="name"
              value={name}
              onChange={event => this.setState(updateByPropertyName('name', event.target.value))}
              type="text"
              placeholder="Project Name"
            />
          </label>
          <label htmlFor="rate">
            Project Rate
            <input
              id="rate"
              value={rate}
              onChange={event => this.setState(updateByPropertyName('rate', event.target.value))}
              type="text"
              placeholder="Project Rate"
            />
          </label>
          <label htmlFor="active">
            Active
            <input
              id="active"
              checked={active}
              onChange={event => this.setState(updateByPropertyName('active', event.target.checked))}
              type="checkbox"
            />
          </label>
          <button disabled={isInvalid} type="submit" className="edit-project-button">
            Update Project
          </button>
        </form>
      </div>
    )
  }
}

EditProjectForm.propTypes = {
  uid: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  createdAt: PropTypes.number.isRequired,
}

EditProjectForm.defaultProps = {
  uid: null,
}

export default EditProjectForm
