import React from 'react'
import propTypes from 'prop-types'

const SelectYear = ({ selectedYear, selectYearFunc }) => {
  const years = ['2016', '2017', '2018', '2019', '2020', '2021']
  const selectHandler = (e) => {
    e.preventDefault()

    selectYearFunc(e.target.value)
  }

  const options = years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ))

  return (
    <div className="select-year">
      <div className="arrow-left" />
      <select
        defaultValue={selectedYear}
        onChange={(e) => selectHandler(e)}
      >
        {options}
      </select>
      <div className="arrow-right" />
    </div>
  )
}

SelectYear.propTypes = {
  selectedYear: propTypes.number.isRequired,
  selectYearFunc: propTypes.func.isRequired,
}

export default SelectYear
