import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  NavLink,
  withRouter,
} from 'react-router-dom'
import SignInWithFacebook from '../SignIn/SignInWithFacebook'
import SignInWithGoogle from '../SignIn/SignInWithGoogle'
import SignInWithGitHub from '../SignIn/SignInWithGitHub'

import { auth, db } from '../../firebase'
import * as routes from '../../constants/routes'

import '../SignIn/signin.css'

const SignUpPage = ({ history }) => (
  <div className="signin">
    <section className="signin-yellow-bg signin-section">
      <h1 className="title">
        SignUp
      </h1>
      <SignUpForm history={history} />
      <div className="signin-yellow-bg-arrow-down">
        or
      </div>
    </section>
    <section className="signin-section">
      <SignInWithFacebook history={history} />
      <SignInWithGoogle history={history} />
      <SignInWithGitHub history={history} />
    </section>
  </div>
)

SignUpPage.propTypes = { history: PropTypes.shape(PropTypes.object) }
SignUpPage.defaultProps = { history: undefined }

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
}

class SignUpForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    const {
      username,
      email,
      passwordOne,
    } = this.state

    const {
      history,
    } = this.props

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in your own accessible Firebase Database too
        db.doCreateUser(authUser.user.uid, username, email, 'Email')
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }))
            history.push(routes.PROJECTS)
          })
          .catch(error => this.setState(updateByPropertyName('error', error)))
      })
      .catch(error => this.setState(updateByPropertyName('error', error)))

    event.preventDefault()
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state

    const isInvalid = passwordOne !== passwordTwo
      || passwordOne === ''
      || username === ''
      || email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          value={username}
          onChange={event => this.setState(updateByPropertyName('username', event.target.value))}
          type="text"
          placeholder="Full Name"
        />
        <input
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <input
          value={passwordOne}
          onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
          type="password"
          placeholder="Password"
        />
        <input
          value={passwordTwo}
          onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
          type="password"
          placeholder="Confirm Password"
        />
        <button disabled={isInvalid} type="submit" className="login--button">
          Sign Up
        </button>

        { error && (
          <p>
            {error.message}
          </p>
        )}
      </form>
    )
  }
}

SignUpForm.propTypes = { history: PropTypes.shape(PropTypes.object) }
SignUpForm.defaultProps = { history: undefined }

const SignUpLink = () => (
  <div className="create-account">
    <NavLink to={routes.SIGN_UP}>
      Create an Account
    </NavLink>
  </div>
)

export default withRouter(SignUpPage)

export {
  SignUpForm,
  SignUpLink,
}
