import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { auth, db } from '../../firebase'
import * as routes from '../../constants/routes'

class SignInWithGitHub extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()

    const { history } = this.props

    auth.doSignInWithProvider('Github')
      .then((authUser) => {
        const { uid, displayName, email } = authUser.user

        // Create user if doesn't exist
        db.onceGetUser(uid)
          .then(snapshot => snapshot.val())
          .then((user) => {
            if (!user) {
              db.doCreateUser(uid, displayName, email, 'Github')
                .then(() => {
                  history.push(routes.LOAD_DATA)
                })
              // Todo: add catch() redux error
            } else {
              // Update login
              db.doUpdateUserLogin(uid, Date.now())
                .then(() => {
                  history.push(routes.LOAD_DATA)
                })
            }
          })

        history.push(routes.LOAD_DATA)
      })
      .catch(console.error) /* eslint-disable-line */
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit" className="login--button provider-login--github provider-login">
          Login with GitHub
        </button>
      </form>
    )
  }
}

SignInWithGitHub.propTypes = { history: PropTypes.shape(PropTypes.object) }
SignInWithGitHub.defaultProps = { history: undefined }

export default SignInWithGitHub
