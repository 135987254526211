import { compose } from 'recompose'
import { connect } from 'react-redux'
import withAuthorization from '../Session/withAuthorization'
import LoadData from './LoadData'

const mapStateToProps = (state) => ({
  uid: state.sessionState.authUser.uid,
  projects: state.userState.projects,
  user: state.userState.user,
  timesheet: state.userState.timesheet,
})

const mapDispatchToProps = (dispatch) => ({
  getUser: (user) => dispatch({ type: 'SET_USER', user }),
  getProjects: (projects) => dispatch({ type: 'SET_PROJECTS', projects }),
  getTimesheet: (timesheet) => dispatch({ type: 'SET_TIMESHEET', timesheet }),
})

const authCondition = (authUser) => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
)(LoadData)
