import React from 'react'
import PropTypes from 'prop-types'

import { StandardList } from '../../styles'

const formatDate = (currentDate) => `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1
}-${currentDate.getDate()}`

const ProjectContractsList = ({ projectContracts }) => (
  <div>
    <h4>
      Contracts
    </h4>
    <form className="standard-form">
      <StandardList>
        {projectContracts.length ? (
          projectContracts.map((contract) => (
            <li>
              {`${formatDate(new Date(contract.startDate))} - ${formatDate(
                new Date(contract.endDate),
              )} (${contract.rate}:-/hour)`}
            </li>
          ))
        ) : (
          <li>
            No contracts for this project
          </li>
        )}
      </StandardList>
    </form>
  </div>
)

ProjectContractsList.propTypes = {
  projectContracts: PropTypes.arrayOf({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.string,
    rate: PropTypes.number,
  }).isRequired,
}

export default ProjectContractsList
