import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as routes from '../../constants/routes'
import { auth } from '../../firebase'
import { unSetUser } from '../../reducers/user'

const SignOutButton = ({ unsetUser, history }) => {
  const unsetUserOnClick = () => {
    auth.doSignOut()
    unsetUser()

    history.push(routes.SIGN_IN)
  }

  return (
    <button
      type="button"
      onClick={unsetUserOnClick}
    >
      Sign Out
    </button>
  )
}

SignOutButton.propTypes = {
  unsetUser: PropTypes.func.isRequired,
  history: PropTypes.shape(PropTypes.object),
}

SignOutButton.defaultProps = { history: undefined }

const mapDispatchToProps = dispatch => ({
  unsetUser: () => dispatch(unSetUser()),
})

export default withRouter(connect(null, mapDispatchToProps)(SignOutButton))
