import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import { db } from '../../firebase'
import * as routes from '../../constants/routes'

import './load-data.css'

const LoadData = ({
  history, uid, user, projects,
  getUser, getProjects, getTimesheet,
}) => {
  if (!user) {
    const setUser = db.getUser(uid)
    setUser.on('value', (snapshot) => {
      getUser(snapshot.val())
    })
  }

  if (!projects) {
    const setProjects = db.getUserProjects(uid)
    setProjects.on('value', (snapshot) => {
      const fetchedProjects = snapshot.val() || []
      getProjects(fetchedProjects)
    })
  }

  if (!user && !projects) {
    return (
      <section className="load-data">
        <Spinner />
      </section>
    )
  }

  if (projects && projects.length === 0) {
    history.push(routes.PROJECTS)
    return (
      <div>
        No project selected
      </div>
    )
  }

  if (user && user.selectedProject) {
    const setTimesheet = db.getUserTimesheets(uid, user.selectedProject.id)
    setTimesheet.on('value', (snapshot) => {
      const timesheet = snapshot.val() ? snapshot.val() : {}
      getTimesheet(timesheet)
    })

    history.push(routes.TIMESHEET)
  }

  if (user && !user.selectedProject) {
    history.push(routes.HOME)
  }

  return (
    <section className="load-data">
      Something went wrong loading data.
    </section>
  )
}

LoadData.propTypes = {
  uid: PropTypes.string,
  user: PropTypes.shape(PropTypes.object),
  projects: PropTypes.arrayOf(PropTypes.object),
  getUser: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getTimesheet: PropTypes.func.isRequired,
  history: PropTypes.shape(PropTypes.object),
}

LoadData.defaultProps = {
  uid: null,
  user: null,
  projects: null,
  history: null,
}

export default LoadData
