import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { SignUpLink } from '../SignUp'
import { PasswordForgetLink } from '../PasswordForget'
import SignInWithFacebook from './SignInWithFacebook'
import SignInWithGoogle from './SignInWithGoogle'
import SignInWithGitHub from './SignInWithGitHub'
import { auth, db } from '../../firebase'
import * as routes from '../../constants/routes'

import './signin.css'

const SignInPage = ({ history }) => (
  <div className="signin">
    <section className="signin-yellow-bg signin-section">
      <h1 className="title">
        Login
      </h1>
      <SignInForm history={history} />
      <PasswordForgetLink />
      <div className="signin-yellow-bg-arrow-down">
        or
      </div>
    </section>
    <section className="signin-section">
      <SignInWithFacebook history={history} />
      <SignInWithGoogle history={history} />
      <SignInWithGitHub history={history} />
      <SignUpLink />
    </section>
  </div>
)

SignInPage.propTypes = { history: PropTypes.shape(PropTypes.object) }
SignInPage.defaultProps = { history: undefined }

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

class SignInForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()

    const {
      email,
      password,
    } = this.state

    const {
      history,
    } = this.props

    auth.doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        const { uid } = authUser.user

        db.doUpdateUserLogin(uid, Date.now())

        this.setState(() => ({ ...INITIAL_STATE }))
        history.push(routes.LOAD_DATA)
      })
      .catch((error) => {
        this.setState(updateByPropertyName('error', error))
      })
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state

    const isInvalid = password === ''
    || email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          id="email"
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <input
          id="password"
          value={password}
          onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
          type="password"
          placeholder="Password"
        />
        <button disabled={isInvalid} type="submit" className="login--button">
          Login
        </button>

        { error
          && (
          <p>
            {error.message}
          </p>
          )
        }
      </form>
    )
  }
}

SignInForm.propTypes = { history: PropTypes.shape(PropTypes.object) }
SignInForm.defaultProps = { history: undefined }

export default withRouter(SignInPage)

export {
  SignInForm,
}
