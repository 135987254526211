import React from 'react'
import SelectProject from './selectProjectContainer'

import './projectHeader.css'

const ProjectHeader = () => (
  <header className="project-header">
    <div className="project-header-content">
      <div className="header-item header-title">
        <h3 className="header-title">
        Project
        </h3>
      </div>
      <div className="header-item">
        <div className="header-icon-wrapper">
          <SelectProject />
        </div>
      </div>
    </div>
  </header>
)

export default ProjectHeader
