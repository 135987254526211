import React from 'react'
import PropTypes from 'prop-types'
import FormStyles from '../../styles/FormStyles'

const UserProfile = ({ user, onChange }) => {
  const { username, email } = user
  return (
    <FormStyles>
      <h3>
        User profile
      </h3>
      <form className="standard-form">
        <label htmlFor="true">
          Username
          <input
            id="username"
            value={username || ''}
            onChange={(e) => onChange(e, 'username')}
            type="text"
            placeholder="username"
          />
        </label>
        <label htmlFor="true">
          Email
          <input
            id="email"
            value={email || ''}
            onChange={(e) => onChange(e, 'email')}
            type="text"
            placeholder="email"
          />
        </label>
      </form>
    </FormStyles>
  )
}

UserProfile.propTypes = {
  user: PropTypes.shape(PropTypes.object),
  onChange: Function.isRequired,
}

UserProfile.defaultProps = { user: undefined }

export default UserProfile
