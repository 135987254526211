import React from 'react'
import PropTypes from 'prop-types'
import Week from './Week'

const Weekly = ({
  date,
  weeks,
  timeSheet,
  updateTimeSheetFunc,
  activeContracts,
}) => (
  <div className="weeks">
    {Object.keys(weeks).map((key) => (
      <Week
        key={key}
        weekNumber={key}
        date={date}
        week={weeks[key]}
        timeSheet={timeSheet}
        updateTimeSheetFunc={updateTimeSheetFunc}
        activeContracts={activeContracts}
      />
    ))}
  </div>
)

Weekly.propTypes = {
  date: PropTypes.objectOf(PropTypes.object),
  weeks: PropTypes.objectOf(PropTypes.array),
  timeSheet: PropTypes.objectOf(PropTypes.object),
  updateTimeSheetFunc: PropTypes.func.isRequired,
  activeContracts: PropTypes.objectOf(PropTypes.object).isRequired,
}

Weekly.defaultProps = {
  date: null,
  weeks: null,
  timeSheet: null,
}

export default Weekly
