import { db } from './firebase'

// User API

export const doCreateUser = (id, username, email, provider) => {
  const createdAt = Date.now()

  db.ref(`users/${id}`).set({
    username,
    email,
    provider,
    createdAt,
    loggedInAt: createdAt,
  })
}

export const onceGetUser = (uid) => db.ref(`users/${uid}`).once('value')

export const getUser = (uid) => db.ref(`users/${uid}`)

export const setUserProject = ({ uid, selectedProject }) => {
  db.ref(`users/${uid}/selectedProject`).set({
    ...selectedProject,
  })
}

export const doUpdateUserLogin = (uid, loggedInAt) => db.ref().child(`users/${uid}`).update({ loggedInAt })

export const updateUserAccount = (uid, user) => db.ref(`users/${uid}`).set({
  ...user,
})

// Project API

export const addProject = ({ uid, name, rate }) => {
  const newProjectKey = db.ref().child(`projects/${uid}`).push().key
  const createdAt = Date.now()

  db.ref(`projects/${uid}/${newProjectKey}`).set({
    name,
    rate,
    active: true,
    createdAt,
  })
}

export const getUserProjects = (uid) => db.ref(`projects/${uid}`).orderByChild('createdAt')

// Timesheet API

export const getUserTimesheets = (uid, pid) => db.ref(`timesheets/${uid}/${pid}`)

export const updateUserTimesheets = (uid, pid, date, hours, rate) => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const monthIndex = d.getMonth()
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const monthNames = ['January', 'February', 'April', 'March', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  db.ref(`timesheets/${uid}/${pid}/${year}/${monthNames[monthIndex]}/${year}-${month}-${day}`).set({
    hours,
    rate,
  })
}

export const editProject = ({
  uid, name, rate, active, projectId, createdAt,
}) => {
  db.ref(`projects/${uid}/${projectId}`).set({
    name,
    rate,
    active,
    createdAt,
  })
}
export const getProjectContracts = (uid, projectId) => db
  .ref(`projectContracts/${uid}/${projectId}`)
  .orderByChild('createdAt')

export const addProjectContract = (uid, projectId, contract) => {
  db.ref(`projectContracts/${uid}/${projectId}`).push(contract)
}
