import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const prodConfig = {
  apiKey: 'AIzaSyAEDC9mCfuesUANyE8f2zwV4q5cMq14vYY',
  authDomain: 'craven-economics.firebaseapp.com',
  databaseURL: 'https://craven-economics.firebaseio.com',
  projectId: 'craven-economics',
  storageBucket: 'craven-economics.appspot.com',
  messagingSenderId: '781574312250',
}

const devConfig = {
  apiKey: 'AIzaSyDqLNUxn-pjQrRyvOC53QlG_ccvDU5Djhc',
  authDomain: 'craven-economics-dev.firebaseapp.com',
  databaseURL: 'https://craven-economics-dev.firebaseio.com',
  projectId: 'craven-economics-dev',
  storageBucket: 'craven-economics-dev.appspot.com',
  messagingSenderId: '582707271832',
}

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

const db = firebase.database()
const auth = firebase.auth()

export {
  db,
  auth,
}
