import firebase from 'firebase/app'
import { auth } from './firebase'

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password) /* eslint-disable-line */

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password) /* eslint-disable-line */

// Sign out
export const doSignOut = () => auth.signOut()

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email)

// Password Change
export const doPasswordUpdate = password => auth.currentUser.updatePassword(password)

export const doSignInWithProvider = (provider) => {
  const authProvider = new firebase.auth[`${provider}AuthProvider`]()
  return firebase.auth().signInWithPopup(authProvider)
}
