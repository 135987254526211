
import React from 'react'
import PropTypes from 'prop-types'
import SelectYear from './selectYear'
import SelectMonth from './selectMonth'

const DatePicker = ({
  selectedMonth,
  selectMonthFunc,
  selectedYear,
  selectYearFunc,
}) => (
  <div className="timesheet-date-wrapper">
    <div className="timesheet-date-wrapper__item">
      <SelectYear selectedYear={selectedYear} selectYearFunc={selectYearFunc} />
    </div>
    <div className="timesheet-date-wrapper__item">
      <SelectMonth selectedMonth={selectedMonth} selectMonthFunc={selectMonthFunc} />
    </div>
  </div>
)

DatePicker.propTypes = {
  selectedMonth: PropTypes.string.isRequired,
  selectMonthFunc: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
  selectYearFunc: PropTypes.func.isRequired,
}

export default DatePicker
