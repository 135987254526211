import React from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from '../helpers'

import './weeklySummary.css'

const WeeklySummary = ({ totalHours, totalPrice }) => (
  <div className="weekly-summary">
    {totalHours}
    {' '}
    hours (
    {formatPrice(totalPrice)}
    )
  </div>
)

WeeklySummary.propTypes = {
  totalHours: PropTypes.number,
  totalPrice: PropTypes.number,
}

WeeklySummary.defaultProps = {
  totalHours: 0,
  totalPrice: 0,
}

export default WeeklySummary
