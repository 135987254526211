import React from 'react'
import HeaderTime from './HeaderTime'

const TimesheetHeader = () => (
  <header className="timesheet-header">
    <h1 className="timesheet-title title">
    Weekly Timesheet
    </h1>
    <div className="timesheet-date">
      <HeaderTime />
    </div>
  </header>
)

export default TimesheetHeader
