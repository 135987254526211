import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as routes from '../../constants/routes'
import lock from '../../images/lock.svg'

import './app-header.css'

const AppHeader = ({ authUser }) => (
  <section className="app-header">
    <div className="app-logo">
      <span className="app-logo__black">
        easy
      </span>
      :
      <span className="app-logo__orange">
        Time
      </span>
      <span className="beta-tag">
        BETA
      </span>
    </div>
    { authUser ? <AppHeaderAuth /> : <AppHeaderNonAuth /> }
  </section>
)

AppHeader.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }),
}

AppHeader.defaultProps = {
  authUser: null,
}

const AppHeaderAuth = () => (
  <div>
    <NavLink to={routes.ACCOUNT}>
      Account
    </NavLink>
  </div>
)

const AppHeaderNonAuth = () => (
  <div className="login-menu">
    <NavLink to={routes.SIGN_UP}>
      <button type="button">
        Signup
      </button>
    </NavLink>
    <NavLink to={routes.SIGN_IN}>
      <img src={lock} alt="Login here" />
    </NavLink>
  </div>
)

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
})

export default connect(mapStateToProps)(AppHeader)
