import React from 'react'
import PropTypes from 'prop-types'
import { StandardList } from '../../styles'

const formatDate = (currentDate) => `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1
}-${currentDate.getDate()}`

const ProjectContractHeader = ({ projectContracts }) => (
  <div>
    Open contracts:
    <StandardList>
      {projectContracts && projectContracts.length ? (
        projectContracts.map((contract) => (
          <li key={contract.startDate}>
            {`${formatDate(new Date(contract.startDate))} - ${formatDate(
              new Date(contract.endDate),
            )} (${contract.rate}:-)`}
          </li>
        ))
      ) : (
        <li>
          No contracts for this period.
        </li>
      )}
    </StandardList>
  </div>
)

ProjectContractHeader.propTypes = {
  projectContracts: PropTypes.arrayOf({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.string,
    rate: PropTypes.number,
  }),
}

ProjectContractHeader.defaultProps = {
  projectContracts: null,
}

export default ProjectContractHeader
