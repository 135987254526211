import React from 'react'
import PropTypes from 'prop-types'
import FormStyles from '../../styles/FormStyles'

const CompanyProfile = ({
  user,
  onChange,
}) => {
  const {
    companyName,
    companyRegNumber,
    companyStreet,
    companyPostalCode,
    companyPostalAddress,
    companyTelephone,
  } = user

  return (
    <FormStyles>
      <h3>
        Company profile
      </h3>
      <form className="standard-form">
        <label htmlFor="true">
          Company name
          <input
            id="company-name"
            value={companyName || ''}
            onChange={(e) => onChange(e, 'companyName')}
            type="text"
            placeholder="Company name"
          />
        </label>
        <label htmlFor="true">
          Registration number
          <input
            id="company-reg-number"
            value={companyRegNumber || ''}
            onChange={(e) => onChange(e, 'companyRegNumber')}
            type="text"
            placeholder="Registration number"
          />
        </label>
        <label htmlFor="true">
          Street
          <input
            id="company-street"
            value={companyStreet || ''}
            onChange={(e) => onChange(e, 'companyStreet')}
            type="text"
            placeholder="Street"
          />
        </label>
        <label htmlFor="true">
          Postal Code
          <input
            id="company-postalcode"
            value={companyPostalCode || ''}
            onChange={(e) => onChange(e, 'companyPostalCode')}
            type="text"
            placeholder="Postal code"
          />
        </label>
        <label htmlFor="true">
          Postal Address
          <input
            id="company-postaladdress"
            value={companyPostalAddress || ''}
            onChange={(e) => onChange(e, 'companyPostalAddress')}
            type="text"
            placeholder="Postal address"
          />
        </label>
        <label htmlFor="true">
          Telephone
          <input
            id="company-telephone"
            value={companyTelephone || ''}
            onChange={(e) => onChange(e, 'companyTelephone')}
            type="text"
            placeholder="Telephone"
          />
        </label>
      </form>
    </FormStyles>
  )
}

CompanyProfile.propTypes = {
  user: PropTypes.shape(PropTypes.object),
  onChange: Function.isRequired,
}

CompanyProfile.defaultProps = { user: undefined }

export default CompanyProfile
