import styled from 'styled-components'

export default styled.div`
  text-align: center;

  & > a {
    color: var(--black);
    text-decoration: none;
  }

  .page-section {
    position: relative;
    padding: 0.5rem 0 0.8rem;
  }

  .page-yellow-bg {
    position: relative;
    background-image: linear-gradient(var(--lightyellow), var(--yellow));
    padding-bottom: 2rem;
  }

  .yellow-bg-arrow-down {
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    background: var(--white);
    width: 35px;
    height: 35px;
    border-radius: 25px;
    box-shadow: 0 2px #ccc;
    font-family: "Lobster", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 2.1rem;
    letter-spacing: 2px;
    padding-left: 3px;
  }

  & p {
    padding: 0 1rem;
  }

  & input[type="text"],
  & input[type="password"] {
    display: block;
    margin: 0.1rem 0;
    padding: 0.8rem 1rem;
    background: var(--white);
    border: none;
    text-align: left;
    transition: all 0.2s ease-in;
    width: 100%;
  }

  & textarea {
    display: block;
    width: 100%;
    height: 100px;
    padding: 0.8rem 1rem;
  }

  .page-button {
    margin: 0.3rem 0;
    padding: 0.7rem 0;
    background: var(--yellow);
    color: var(--black);
    border: 1px solid var(--black);
    border-radius: 2px;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
    min-width: 158px;
  }

  .page-button:hover {
    background: var(--orange);
    color: var(--white);
    border-color: var(--white);
    transition: all 0.5s ease-out;
    cursor: pointer;
  }

  .page-button:disabled {
    background: var(--lightgray);

    &:hover {
      background: var(--lightgray);
      color: var(--black);
      border: 1px solid var(--black);
    }
  }
`
