import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { firebase } from '../../firebase'
import * as routes from '../../constants/routes'

const withAuthorization = condition => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        const { history } = this.props

        if (!condition(authUser)) {
          history.push(routes.SIGN_IN)
        }
      })
    }

    render() {
      const { authUser } = this.props
      return authUser ? <Component {...this.props} /> : null
    }
  }

  WithAuthorization.propTypes = {
    history: PropTypes.shape(PropTypes.object),
    authUser: PropTypes.shape(PropTypes.object),
  }
  WithAuthorization.defaultProps = {
    history: undefined,
    authUser: null,
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  })

  return compose(
    withRouter,
    connect(mapStateToProps),
  )(WithAuthorization)
}

export default withAuthorization
