import React from 'react'
import propTypes from 'prop-types'
import { monthNames } from '../helpers'

const SelectMonth = ({ selectedMonth, selectMonthFunc }) => {
  const selectMonthHandler = (e) => {
    e.preventDefault()

    selectMonthFunc(e.target.value)
  }

  const options = monthNames.map((name, i) => (
    <option key={name} value={i}>
      {name}
    </option>
  ))

  return (
    <select
      defaultValue={selectedMonth}
      onChange={e => selectMonthHandler(e)}
    >
      {options}
    </select>
  )
}

SelectMonth.propTypes = {
  selectedMonth: propTypes.number.isRequired,
  selectMonthFunc: propTypes.func.isRequired,
}

export default SelectMonth
