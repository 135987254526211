import { createStore, applyMiddleware } from 'redux'
import { createMiddleware } from 'redux-beacon'
import GoogleAnalytics, { trackEvent } from '@redux-beacon/google-analytics'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'

const eventsMap = {
  '*': trackEvent(action => ({
    category: 'redux',
    action: action.type,
  })),
}

const trackingMiddleware = createMiddleware(eventsMap, GoogleAnalytics())

let environmentDevTools

if (process.env.NODE_ENV === 'production') {
  environmentDevTools = composeWithDevTools(applyMiddleware(trackingMiddleware))
} else {
  environmentDevTools = composeWithDevTools()
}

const store = createStore(rootReducer, environmentDevTools)

export default store
