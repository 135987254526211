import React from 'react'
import { getCurrentMonth } from '../helpers'

import './headerTime.css'

const HeaderTime = () => {
  const currentDay = new Date().getDate()
  const currentMonth = getCurrentMonth()
  const currentYear = new Date().getFullYear()

  return (
    <div className="header-item header-time">
      <div className="header-date-item header-date-day">
        {currentDay}
      </div>
      <div className="header-date-item">
        <span className="header-date-month">
          {currentMonth}
        </span>
        <span className="header-date-year">
          {currentYear}
        </span>
      </div>
    </div>
  )
}

export default HeaderTime
