import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import SelectProject from '../Projects/selectProjectContainer'
import ProjectContractsList from './ProjectContractList'

import { FormStyles } from '../../styles'

import 'react-datepicker/dist/react-datepicker.css'

const ProjectsProfile = ({ project, addProjectContract, projectContracts }) => {
  const [createContractForm, setCreateContractForm] = useState(false)
  const [contract, setContract] = useState(null)
  const [confirmationText, setConfirmationText] = useState(null)
  const currentDate = new Date()
  const toggleDisplayForm = () => {
    setCreateContractForm(!createContractForm)
    setConfirmationText(null)
  }

  const onAddContract = () => {
    const { startDate, endDate, rate } = contract
    const formatedContract = {
      ...contract,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      rate: rate || 0,
    }
    addProjectContract(project.id, formatedContract)

    setContract(null)
    setCreateContractForm(false)
    setConfirmationText(`Contract added for ${project.name}!`)
  }

  return (
    <FormStyles>
      <h3>
        Projects
      </h3>
      Current project
      <SelectProject />
      <ProjectContractsList projectContracts={projectContracts} />
      <button
        type="button"
        onClick={toggleDisplayForm}
        className="page-button"
        disabled={!project}
      >
        Create contract
      </button>
      {createContractForm && (
        <form className="standard-form">
          <label htmlFor="true">
            Start date
            <DatePicker
              id="start-date"
              dateFormat="yyyy-MM-dd"
              selected={
                contract && contract.startDate
                  ? contract.startDate
                  : currentDate
              }
              onChange={(date) => setContract({ ...contract, startDate: date })}
            />
          </label>
          <label htmlFor="true">
            End date
            <DatePicker
              id="end-date"
              dateFormat="yyyy-MM-dd"
              selected={
                contract && contract.endDate ? contract.endDate : currentDate
              }
              onChange={(date) => setContract({ ...contract, endDate: date })}
            />
          </label>
          <label htmlFor="true">
            Hourly rate
            <input
              id="rate"
              value={contract && contract.rate}
              onChange={(e) => setContract({
                ...contract,
                rate: Number(e.currentTarget.value),
              })}
              type="text"
              placeholder="Hourly rate"
            />
          </label>
          <label htmlFor="true">
            Description
            <textarea
              id="descr"
              value={contract && contract.descr}
              onChange={(e) => setContract({ ...contract, descr: e.currentTarget.value })}
              placeholder="Contract description"
            />
          </label>
          <button type="button" onClick={onAddContract} className="page-button">
            Add contract
          </button>
        </form>
      )}
      {confirmationText && (
      <p>
        {confirmationText}
      </p>
      )}
    </FormStyles>
  )
}

ProjectsProfile.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    rate: PropTypes.number,
    active: PropTypes.bool,
  }),
  addProjectContract: Function.isRequired,
  projectContracts: PropTypes.shape(PropTypes.object),
}

ProjectsProfile.defaultProps = {
  project: null,
  projectContracts: null,
}

export default ProjectsProfile
