import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Pen from './Pen'
import { onlyWeekends, findMatchingDay } from '../helpers'
import { hasValidContract } from './utils/findActiveProjectContracts'

import './week.css'

// TEMP styling
const colors = ['red-border', 'blue-border']

const mapColors = (contracts) => contracts.map((contract, i) => ({ ...contract, color: colors[i] }))

const Week = ({
  week,
  weekNumber,
  date,
  timeSheet,
  updateTimeSheetFunc,
  activeContracts,
}) => {
  let weeklyTotal = 0

  const updateTimeSheet = (e, contract) => {
    e.preventDefault()

    updateTimeSheetFunc(e.target.id, e.target.value, contract)
  }

  if (onlyWeekends(week)) return ''

  return (
    <div className="week">
      <h2>
        W
        {weekNumber}
      </h2>
      {week.map((day) => {
        const hoursOfTheDay = findMatchingDay(day, date, timeSheet)
        weeklyTotal += +hoursOfTheDay
        const validContract = hasValidContract(mapColors(activeContracts), day.date)
        if (day.weekDay === 'Sun' || day.weekDay === 'Sat') return ''

        return (
          <div
            className={`week-day${
              day.date === moment().format('YYYY-MM-DD') ? ' is-today' : ''
            }`}
            key={day.date}
          >
            <div className="week-day--header">
              {day.day}
              <br />
              {day.weekDay.substr(0, 3)}
            </div>
            <input
              id={day.date}
              type="number"
              min="0"
              max="24"
              placeholder="0"
              value={findMatchingDay(day, date, timeSheet)}
              onChange={(e) => updateTimeSheet(e, validContract)}
              className={`${validContract ? validContract.color : ''}`}
            />
          </div>
        )
      })}
      <div className="week-day">
        <WeeklySummary total={weeklyTotal} />
        <div className="week-day--summary">
          <button type="button" className="week-day--summary__button">
            <Pen />
          </button>
        </div>
      </div>
    </div>
  )
}

Week.propTypes = {
  weekNumber: PropTypes.string.isRequired,
  date: PropTypes.shape(PropTypes.object),
  week: PropTypes.arrayOf(PropTypes.object),
  timeSheet: PropTypes.objectOf(PropTypes.object),
  updateTimeSheetFunc: PropTypes.func.isRequired,
  activeContracts: PropTypes.objectOf(PropTypes.object).isRequired,
}

Week.defaultProps = {
  date: null,
  week: null,
  timeSheet: null,
}

const WeeklySummary = ({ total }) => (
  <div className="week-day--header week-day--summary__hours">
    <span>
      {total}
      hrs
    </span>
  </div>
)

WeeklySummary.propTypes = { total: PropTypes.number }
WeeklySummary.defaultProps = { total: 0 }

export default Week
