import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { auth } from '../../firebase'
import * as routes from '../../constants/routes'

import '../SignIn/signin.css'

const PasswordForgetPage = () => (
  <div className="signin">
    <section className="signin-yellow-bg signin-section">
      <h1 className="title">
        Forgot password?
      </h1>
      <PasswordForgetForm />
    </section>
  </div>
)

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  email: '',
  error: null,
}

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    const { email } = this.state

    auth.doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }))
      })
      .catch((error) => {
        this.setState(updateByPropertyName('error', error))
      })

    event.preventDefault()
  }

  render() {
    const {
      email,
      error,
    } = this.state

    const isInvalid = email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <input
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit" className="login--button">
          Reset My Password
        </button>

        { error && (
          <p>
            {error.message}
          </p>
        )}
      </form>
    )
  }
}

const PasswordForgetLink = () => (
  <div className="password-forget">
    <NavLink to={routes.PASSWORD_FORGET}>
      Forgot Password?
    </NavLink>
  </div>
)

export default PasswordForgetPage

export {
  PasswordForgetForm,
  PasswordForgetLink,
}
