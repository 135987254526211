import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import withAuthentication from '../Session/withAuthentication'
import * as routes from '../../constants/routes'

import Header from '../AppHeader'
import Footer from '../AppFooter'
import Navigation from '../Navigation'
import LandingPage from '../Landing'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import Projects from '../Projects'
import Timesheet from '../Timesheet'
import AccountPage from '../Account'
import Statistics from '../Statistics'
import LoadData from '../LoadData'

const App = () => (
  <Router>
    <main className="app-container">
      <Header />
      <Navigation />
      <Route exact path={routes.LOAD_DATA} component={() => <LoadData />} />
      <Route exact path={routes.HOME} component={() => <LandingPage />} />
      <Route exact path={routes.SIGN_UP} component={() => <SignUpPage />} />
      <Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
      <Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
      <Route exact path={routes.PROJECTS} component={() => <Projects />} />
      <Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
      <Route exact path={routes.TIMESHEET} component={() => <Timesheet />} />
      <Route exact path={routes.STATISTICS} component={() => <Statistics />} />
      <Footer />
    </main>
  </Router>
)

export default withAuthentication(App)
