import React from 'react'
import PropTypes from 'prop-types'

const SelectProject = ({ projects, selectProjectOnChange, user }) => {
  const selected = user && user.selectedProject ? user.selectedProject.id : ''

  if (!projects) {
    return (
      <div>
        loading projects
      </div>
    )
  }

  if (projects && projects.length === 0) {
    return (
      <div>
        You have no projects yet
      </div>
    )
  }

  const projectList = !projects
    ? ''
    : Object.keys(projects).map((key) => (
      <option key={key} value={key}>
        {projects[key].name}
      </option>
    ))

  return (
    <select value={selected} onChange={(e) => selectProjectOnChange(e, projects)}>
      <option value="">
        Select Project
      </option>
      {projectList}
    </select>
  )
}

SelectProject.propTypes = {
  projects: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      rate: PropTypes.string,
    }).isRequired,
  ),
  selectProjectOnChange: PropTypes.func.isRequired,
  user: PropTypes.shape(PropTypes.object),
}

SelectProject.defaultProps = {
  projects: null,
  user: null,
}

export default SelectProject
