import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import SignOutButton from '../SignOut'
import * as routes from '../../constants/routes'

import './index.css'

const Navigation = ({ authUser }) => (
  <nav className="app-menu-wrapper">
    { authUser
      ? <NavigationAuth />
      : <NavigationNonAuth />
    }
  </nav>
)

Navigation.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }),
}

Navigation.defaultProps = {
  authUser: null,
}

const NavigationAuth = () => (
  <ul className="app-menu">
    <li>
      <NavLink to={routes.PROJECTS} activeClassName="selected">
        Projects
      </NavLink>
    </li>
    <li>
      <NavLink to={routes.TIMESHEET} activeClassName="selected">
        Timesheet
      </NavLink>
    </li>
    <li>
      <NavLink to={routes.STATISTICS} activeClassName="selected">
        Statistics
      </NavLink>
    </li>
    <li>
      <SignOutButton />
    </li>
  </ul>
)

const NavigationNonAuth = () => (
  <ul className="app-menu">
    <li>
      <NavLink to={routes.HOME}>
        Home
      </NavLink>
    </li>
  </ul>
)

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
})

export default connect(mapStateToProps)(Navigation)
