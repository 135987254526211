import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Weekly from './Weekly'
import DatePicker from './DatePicker'
import WeeklySummary from './WeeklySummary'
import ProjectHeader from '../Projects/projectHeader'
import TimesheetHeader from './TimesheetHeader'
import ProjectContractHeader from './ProjectContractHeader'
import SelectProject from '../Projects/selectProjectContainer'
import {
  setDate, getWeeks, summarizetimeSheet,
} from '../helpers'
import { db } from '../../firebase'

import findActiveProjectContracts from './utils/findActiveProjectContracts'

import './timesheet.css'

class Timesheet extends Component {
  constructor(props) {
    super(props)

    const { currentDate } = this.props
    this.state = {
      currentDate,
    }

    this.updateTimesheetFunc = this.updateTimesheetFunc.bind(this)
  }

  componentDidMount() {
    const {
      getProjects,
      getTimesheet,
      uid,
      projects,
      getUser,
      user,
    } = this.props

    if (!user) {
      const setUser = db.getUser(uid)
      setUser.on('value', (snapshot) => {
        getUser(snapshot.val())
      })
    }

    if (!projects) {
      const setProjects = db.getUserProjects(uid)
      setProjects.on('value', (snapshot) => {
        getProjects(snapshot.val())
      })
    }

    if (user && user.selectedProject) {
      const setTimesheet = db.getUserTimesheets(uid, user.selectedProject.id)
      setTimesheet.on('value', (snapshot) => {
        const timesheet = snapshot.val() ? snapshot.val() : {}
        getTimesheet(timesheet)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      user, uid, getTimesheet, timesheet,
    } = this.props

    if (user && user.selectedProject) {
      if (
        nextProps.user.selectedProject !== user.selectedProject
        || !timesheet
      ) {
        const setTimesheet = db.getUserTimesheets(
          uid,
          nextProps.user.selectedProject.id,
        )
        setTimesheet.on('value', (snapshot) => {
          const fetchedTimesheet = snapshot.val() ? snapshot.val() : {}
          getTimesheet(fetchedTimesheet)
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, fetchProjectContracts, projectContracts } = this.props
    const { currentDate } = this.state
    if (user !== prevProps.user || currentDate !== prevState.currentDate) {
      fetchProjectContracts(user.selectedProject.id)
      this.setState((state) => ({
        timeSheet: null,
      }))
    }

    // if contracts loaded, find active contract
    if (projectContracts !== prevProps.projectContracts) {
      const activeContracts = findActiveProjectContracts(
        projectContracts,
        currentDate,
      )
      this.setState((state) => ({
        activeContracts,
      }))
    }
  }

  selectMonthFunc(e) {
    const { setCurrentMonth } = this.props
    setCurrentMonth(e)

    this.setState((state) => {
      const d = new Date(state.currentDate)
      d.setMonth(e)

      return {
        currentDate: d,
      }
    })
  }

  selectYearFunc(e) {
    const { setCurrentYear } = this.props
    setCurrentYear(e)

    this.setState((state) => {
      const d = new Date(state.currentDate)
      d.setFullYear(e)

      return {
        currentDate: d,
      }
    })
  }

  updateTimesheetFunc(date, hours, contract) {
    const { updateTimesheet, user } = this.props

    updateTimesheet(date, hours, user.selectedProject.id, contract.rate)
  }

  render() {
    const { timesheet, user } = this.props
    const { currentDate, activeContracts } = this.state

    if (!user) return <Spinner label="loading user" />
    if (user && !user.selectedProject) return <SelectProject />

    const loadedTimesheet = !timesheet ? {} : timesheet
    const date = setDate(currentDate)
    const summary = summarizetimeSheet(
      new Date(currentDate),
      loadedTimesheet,
      user.selectedProject.rate,
    )

    return (
      <div className="timesheet">
        <TimesheetHeader />
        <ProjectHeader projectRate={user.selectedProject.rate} />
        <DatePicker
          selectedMonth={currentDate.getMonth()}
          selectMonthFunc={(e) => this.selectMonthFunc(e)}
          selectedYear={currentDate.getFullYear()}
          selectYearFunc={(e) => this.selectYearFunc(e)}
        />
        <ProjectContractHeader projectContracts={activeContracts} />
        {activeContracts && activeContracts.length ? (
          <Weekly
            date={date}
            timeSheet={loadedTimesheet}
            weeks={getWeeks(date)}
            updateTimeSheetFunc={this.updateTimesheetFunc}
            activeContracts={activeContracts}
          />
        ) : (
          <p>
            You must provide a valid contract
          </p>
        )}
        <WeeklySummary totalHours={summary[0]} totalPrice={summary[1]} />
      </div>
    )
  }
}

Timesheet.propTypes = {
  uid: PropTypes.string.isRequired,
  projects: PropTypes.shape(PropTypes.object),
  user: PropTypes.shape(PropTypes.object),
  currentDate: PropTypes.objectOf(Date).isRequired,
  timesheet: PropTypes.objectOf(PropTypes.object),
  setCurrentMonth: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  updateTimesheet: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getTimesheet: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  fetchProjectContracts: Function.isRequired,
  projectContracts: PropTypes.arrayOf(PropTypes.object),
}

Timesheet.defaultProps = {
  projects: null,
  timesheet: null,
  user: null,
  projectContracts: null,
}

export default Timesheet
