import React from 'react'
import instagram from '../../images/instagram.svg'
import facebook from '../../images/facebook_1.svg'
import twitter from '../../images/twitter.svg'
import logo from '../../images/c-studio.svg'

import './app-footer.css'

const Footer = () => (
  <footer className="footer">
    <section className="footer-dark-bg">
      <article className="footer-social">
        <h3 className="footer-headline">
          follow us on
        </h3>
        <div className="footer-social-links">
          <a href="https://example.com">
            <img src={instagram} alt="Go to Instagram" />
          </a>
          <a href="https://example.com">
            <img src={facebook} alt="Go to Facebook" />
          </a>
          <a href="https://example.com">
            <img src={twitter} alt="Go to Twitter" />
          </a>
        </div>
      </article>
      <article className="footer-legal">
        <a href="https://example.com">
          privacy policy
        </a>
        <a href="https://example.com">
          about easy:time
        </a>
      </article>
    </section>
    <section className="footer-company">
      <img src={logo} alt="C Studio" />
      <p>
        Copyright © 2018
        {' '}
        <a href="https://craven-studio.com" alt="Craven Studio">
          C Studio AB
        </a>
      </p>
    </section>
  </footer>
)

export default Footer
