import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import withAuthorization from '../Session/withAuthorization'
import { db } from '../../firebase'
import AddProject from './addProject'
import Spinner from '../Spinner'
import { formatDate } from '../helpers'
import SelectProject from './selectProjectContainer'
import EditProject from './editProject'

import './projects.css'

class Projects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: '0',
    }

    this.editProjectOnClick = this.editProjectOnClick.bind(this)
  }

  componentDidMount() {
    const {
      getProjects, uid, projects, getUser, user,
    } = this.props

    if (!user) {
      const setUser = db.getUser(uid)
      setUser.on('value', (snapshot) => {
        getUser(snapshot.val())
      })
    }

    if (!projects) {
      const setProjects = db.getUserProjects(uid)
      setProjects.on('value', (snapshot) => {
        const fetchedProjects = snapshot.val() || {}
        getProjects(fetchedProjects)
      })
    }
  }

  editProjectOnClick(projectId) {
    this.setState(state => (
      { visible: state.visible !== projectId ? projectId : '0' }
    ))
  }

  render() {
    const { visible } = this.state
    const { uid, projects } = this.props

    return (
      <div className="projects">
        <ProjectsList
          projects={projects}
          editProjectOnClick={this.editProjectOnClick}
          visible={visible}
          uid={uid}
        />
        <AddProject uid={uid} />
        <h3>
          Select Project
        </h3>
        <SelectProject />
      </div>
    )
  }
}

Projects.propTypes = {
  getProjects: PropTypes.func.isRequired,
  uid: PropTypes.string,
  projects: PropTypes.shape({
    project: PropTypes.shape({
      name: PropTypes.string.isRequired,
      rate: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
    }),
  }),
  getUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    selectedProject: PropTypes.shape({
      name: PropTypes.string.isRequired,
      rate: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
    }),
  }),
}

Projects.defaultProps = {
  uid: null,
  projects: null,
  user: null,
}

const ProjectsList = ({
  projects,
  editProjectOnClick,
  visible,
  uid,
}) => {
  const markup = projects
    ? Object.keys(projects).map(project => (
      <Project
        key={project}
        projectId={project}
        project={projects[project]}
        editProjectOnClick={() => editProjectOnClick(project)}
        visible={visible}
        uid={uid}
      />
    ))
    : <Spinner />

  return (
    <div className="project-list">
      <header className="project-list-header">
        <h2>
          Projects
        </h2>
      </header>
      <div className="project-list-row project-list-row-header">
        <div className="project-list-item project-list-item--name">
          Name
        </div>
        <div className="project-list-item">
          Created
        </div>
        <div className="project-list-item">
          Action
        </div>
      </div>
      {markup}
    </div>
  )
}

ProjectsList.propTypes = {
  projects: PropTypes.shape({
    project: PropTypes.shape({
      name: PropTypes.string.isRequired,
      rate: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
    }),
  }),
  editProjectOnClick: PropTypes.func.isRequired,
  visible: PropTypes.string,
  uid: PropTypes.string,
}

ProjectsList.defaultProps = {
  uid: null,
  projects: null,
  visible: '0',
}

const Project = ({
  projectId,
  project,
  editProjectOnClick,
  visible,
  uid,
}) => (
  <div className="project-list-row">
    <div className="project-list-item project-list-item--name">
      {project.name}
    </div>
    <div className="project-list-item project-list-item--date">
      <time>
        {formatDate(project.createdAt, true)}
      </time>
    </div>
    <div className="project-list-item">
      <button
        type="button"
        className="project-list-button"
        onClick={editProjectOnClick}
      >
        Edit
      </button>
    </div>
    { projectId === visible && <EditProject {...project} uid={uid} projectId={projectId} /> }
  </div>
)

Project.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    rate: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
  }).isRequired,
  editProjectOnClick: PropTypes.func.isRequired,
  visible: PropTypes.string,
}

Project.defaultProps = {
  visible: '0',
}

const mapStateToProps = state => ({
  uid: state.sessionState.authUser.uid,
  projects: state.userState.projects,
  user: state.userState.user,
})

const mapDispatchToProps = dispatch => ({
  getUser: user => dispatch({ type: 'SET_USER', user }),
  getProjects: projects => dispatch({ type: 'SET_PROJECTS', projects }),
})

const authCondition = authUser => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Projects)
