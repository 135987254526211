const INITIAL_STATE = {
  projects: null,
  projectContracts: null,
  timesheet: null,
  currentDate: new Date(),
  user: null,
}

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
})

export const unSetUser = () => ({
  type: 'UNSET_USER',
  state: undefined,
})

export const setTimesheet = (timesheet) => ({
  type: 'SET_TIMESHEET',
  timesheet,
})

export const updateTimesheet = (timesheet) => ({
  type: 'UPDATE_TIMESHEET',
  timesheet,
})

export const setProjects = (projects) => ({
  type: 'SET_PROJECTS',
  projects,
})

export const addProject = (action) => ({
  type: 'ADD_PROJECT',
  name: action.name,
  rate: action.rate,
})

export const selectProject = ({ uid, selectedProject }) => ({
  type: 'SELECT_PROJECT',
  uid,
  selectedProject,
})

export const setProjectContracts = (projectContracts) => ({
  type: 'SET_PROJECT_CONTRACTS',
  projectContracts,
})

export const addProjectContract = (projectContract) => ({
  type: 'ADD_PROJECT_CONTRACT',
  projectContract,
})

export const setDate = (currentDate) => ({
  type: 'SET_DATE',
  currentDate,
})

export const setMonth = (selectedMonth) => ({
  type: 'SET_MONTH',
  selectedMonth,
})

export const setYear = (selectedYear) => ({
  type: 'SET_YEAR',
  selectedYear,
})

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.user,
      }
    }
    case 'UNSET_USER': {
      return {
        state: action.state,
      }
    }
    case 'SET_TIMESHEET': {
      return {
        ...state,
        timesheet: action.timesheet,
      }
    }
    case 'UPDATE_TIMESHEET': {
      return {
        ...state,
        timesheet: { ...action.timesheet },
      }
    }
    case 'SET_PROJECTS': {
      return {
        ...state,
        projects: action.projects,
      }
    }
    case 'ADD_PROJECT': {
      return {
        ...state,
        name: action.name,
        rate: action.rate,
      }
    }
    case 'SELECT_PROJECT': {
      return {
        ...state,
        uid: action.uid,
        selectedProject: action.selectedProject,
      }
    }
    case 'SET_PROJECT_CONTRACTS': {
      return {
        ...state,
        projectContracts: action.projectContracts ? Object
          .keys(action.projectContracts)
          .map((key) => ({
            ...action.projectContracts[key],
            id: key,
          })) : [],
      }
    }
    case 'ADD_PROJECT_CONTRACT': {
      return {
        ...state,
        projectContracts: [...state.projectContracts, action.projectContract],
      }
    }
    case 'SET_DATE': {
      return {
        ...state,
        currentDate: action.currentDate,
      }
    }
    case 'SET_MONTH': {
      const d = state.currentDate
      d.setMonth(action.selectedMonth)
      return {
        ...state,
        currentDate: d,
      }
    }
    case 'SET_YEAR': {
      const d = state.currentDate
      d.setFullYear(action.selectedYear)
      return {
        ...state,
        currentDate: d,
      }
    }
    default:
      return state
  }
}

export default userReducer
