import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { db } from '../../firebase'

import './add-project.css'

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  name: '',
  active: true,
  rate: '',
  createdAt: null,
}

class AddProjectForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()

    const { uid } = this.props
    const {
      name, rate,
    } = this.state

    db.addProject({ uid, name, rate })

    this.setState(() => ({
      name: '',
      rate: '',
    }))
  }

  render() {
    const {
      name, rate,
    } = this.state
    const isInvalid = name === '' || rate === ''

    return (
      <div className="add-project">
        <form onSubmit={this.onSubmit}>
          <h3>
            Add Project
          </h3>
          <label htmlFor="name">
            Name
            <input
              id="name"
              value={name}
              onChange={event => this.setState(updateByPropertyName('name', event.target.value))}
              type="text"
              placeholder="Project Name"
            />
          </label>
          <label htmlFor="rate">
            Rate
            <input
              id="rate"
              value={rate}
              onChange={event => this.setState(updateByPropertyName('rate', event.target.value))}
              type="number"
              placeholder="Project Rate"
            />
          </label>
          <button disabled={isInvalid} type="submit" className="add-project-button">
            Add Project
          </button>
        </form>
      </div>
    )
  }
}

AddProjectForm.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default AddProjectForm
