export default (contracts, date) => contracts.filter((contract) => {
  const endDate = new Date(contract.endDate)
  const startDate = new Date(contract.startDate)
  const currentDate = new Date(date)

  // if start date is less than current date and end date is more than current date
  return (
    startDate.getFullYear() === currentDate.getFullYear()
    && startDate.getMonth() <= currentDate.getMonth()
    && endDate.getFullYear() >= currentDate.getFullYear()
    && endDate.getMonth() >= currentDate.getMonth()
  )
})

export const hasValidContract = (contracts, date) => contracts.find((contract) => {
  const endDate = new Date(contract.endDate)
  const startDate = new Date(contract.startDate)
  const currentDate = new Date(date)

  endDate.setHours(0, 0, 0, 0)
  startDate.setHours(0, 0, 0, 0)
  currentDate.setHours(0, 0, 0, 0)

  // if start date is less than current date and end date is more than current date
  return (
    startDate.getTime() <= currentDate.getTime()
      && endDate.getTime() >= currentDate.getTime()
  )
})
