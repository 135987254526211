import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PasswordForgetForm } from '../PasswordForget'
import PasswordChangeForm from '../PasswordChange'
import CompanyProfile from './CompanyProfile'
import UserProfile from './UserProfile'
import ProjectsProfile from './ProjectsProfile'

import { PageStyles } from '../../styles'

const AccountPage = ({
  user,
  fetchUser,
  fetchProjectContracts,
  updateAccount,
  addProjectContract,
  projectContracts,
}) => {
  useEffect(() => {
    if (!user) {
      fetchUser()
    }
  }, [])

  useEffect(() => {
    if (user && !projectContracts) {
      fetchProjectContracts(user.selectedProject)
    }
  }, user)

  const onChange = (e, label) => {
    const { value } = e.currentTarget
    const updatedUser = { ...user, [label]: value }

    updateAccount(updatedUser)
  }

  return (
    <PageStyles>
      <section className="page-yellow-bg page-section">
        <h1 className="title">
          Account Details
        </h1>
        {user && projectContracts && (
          <ProjectsProfile
            project={user.selectedProject}
            addProjectContract={addProjectContract}
            projectContracts={projectContracts}
          />
        )}
        {user && <UserProfile user={user} onChange={onChange} />}
        {user && <CompanyProfile user={user} onChange={onChange} />}
        <h3>
          Company Employees
        </h3>
        <ul>
          <li>
            Jörgen Thelin
          </li>
        </ul>
        <hr />
        <h3>
          Actions
        </h3>
        <ul>
          <li>
            <a href="">
              Reset password
            </a>
            {/* <PasswordForgetForm /> */}
          </li>
          <li>
            <a href="">
              Change password
            </a>
            {/* <PasswordChangeForm /> */}
          </li>
        </ul>
      </section>
    </PageStyles>
  )
}

AccountPage.propTypes = {
  fetchUser: Function.isRequired,
  updateAccount: Function.isRequired,
  addProjectContract: Function.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string.isRequired,
    selectedProject: PropTypes.shape(PropTypes.object),
  }),
  fetchProjectContracts: Function.isRequired,
  projectContracts: PropTypes.shape(PropTypes.object),
}

AccountPage.defaultProps = {
  user: null,
  projectContracts: null,
}

export default AccountPage
