import moment from 'moment'

export function formatPrice(amount) {
  return `${amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}:-`
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function getCurrentMonth() {
  const d = new Date()

  return monthNames[d.getMonth()]
}

export function getWeeks(date) {
  let m = date
  const DAYS = m.daysInMonth()
  const weeks = {}

  for (let i = 0; i < DAYS; i += 1) {
    const weekNumber = m.week()
    const day = m.format('DD')
    const fullDate = m.format('YYYY-MM-DD')
    const weekDay = m.format('ddd')
    const dayObject = {
      weekDay,
      day,
      date: fullDate,
    }

    if (!weeks[weekNumber]) weeks[weekNumber] = []

    weeks[weekNumber].push(dayObject)

    m = m.clone().add(1, 'd')
  }

  return weeks
}

export const setDate = (d = new Date()) => {
  const y = d.getFullYear()
  const m = d.getMonth()
  const firstDayOfMonth = new Date(y, m, 1)
  return moment(firstDayOfMonth)
}

export const formatDate = (dateStr, short = false) => {
  const d = new Date(dateStr)
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()
  const monthNameStr = !short ? monthNames[month] : monthNamesShort[month]
  let daySuffix

  switch (day) {
    case 1:
    case 2:
    case 21:
    case 22:
    case 31:
      daySuffix = 'a'
      break
    default:
      daySuffix = 'e'
  }

  return `${day}:${daySuffix} ${monthNameStr} ${year}`
}

const isNotWeekend = (week) => week.weekDay === 'Mon'
    || week.weekDay === 'Tue'
    || week.weekDay === 'Wed'
    || week.weekDay === 'Thu'
    || week.weekDay === 'Fri'

export function onlyWeekends(week) { return !week.find(isNotWeekend) }

export const summarizetimeSheet = (date, timeSheet, defaultRate) => {
  const year = date.getFullYear()
  const month = monthNames[date.getMonth()]

  if (!timeSheet) return 0
  if (!timeSheet[year]) return 0
  if (!timeSheet[year][month]) return 0
  return Object.keys(timeSheet[year][month]).reduce((sum, key) => {
    const newSum = timeSheet[year][month][key]
    if (newSum) {
      if (typeof newSum === 'object') {
        return [sum[0] + +newSum.hours, newSum.hours * newSum.rate + sum[1]]
      }
      return [sum[0] + +newSum, newSum * defaultRate + sum[1]]
    }
    return sum
  }, [0, 0])
}

export const summarizetimeSheet2 = (year, month, timeSheet) => {
  if (!timeSheet) return 0
  if (!timeSheet[year]) return 0
  if (!timeSheet[year][month]) return 0

  return Object.keys(timeSheet[year][month]).reduce((sum, key) => (
    timeSheet[year][month][key]
      ? sum + parseFloat(timeSheet[year][month][key])
      : sum
  ), 0)
}

export const findMatchingDay = (day, date, timeSheet) => {
  const year = date.format('YYYY')
  const month = date.format('MMMM')

  if (!timeSheet || !timeSheet[year] || !timeSheet[year][month]) return ''

  const match = Object.keys(timeSheet[year][month]).find((key) => key === day.date)
  if (match) {
    return typeof timeSheet[year][month][match] === 'object'
      ? timeSheet[year][month][match].hours
      : timeSheet[year][month][match]
  }

  return ''
}
