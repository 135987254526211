import { compose } from 'recompose'
import { connect } from 'react-redux'
import Timesheet from './Timesheet'
import withAuthorization from '../Session/withAuthorization'
import { db } from '../../firebase'
import {
  setMonth,
  setYear,
  setUser,
  setProjects,
  setTimesheet,
  setProjectContracts,
} from '../../reducers/user'

const mapStateToProps = (state) => ({
  uid: state.sessionState.authUser.uid,
  projects: state.userState.projects,
  user: state.userState.user,
  timesheet: state.userState.timesheet,
  currentDate: state.userState.currentDate,
  projectContracts: state.userState.projectContracts,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUser: (user) => dispatch(setUser(user)),
  getProjects: (projects) => dispatch(setProjects(projects)),
  getTimesheet: (timesheet) => dispatch(setTimesheet(timesheet)),
  updateTimesheet: (date, hours, projectId, rate) => {
    const { uid } = ownProps.authUser

    db.updateUserTimesheets(uid, projectId, date, hours, rate)

    // dispatch({ type: 'UPDATE_TIMESHEET', hours })
  },
  fetchProjectContracts: (projectId) => {
    const { uid } = ownProps.authUser
    db.getProjectContracts(uid, projectId).on('value', (snapshot) => {
      dispatch(setProjectContracts(snapshot.val()))
    })
  },
  setCurrentMonth: (selectedMonth) => {
    dispatch(setMonth(selectedMonth))
  },
  setCurrentYear: (selectedYear) => {
    dispatch(setYear(selectedYear))
  },
})

const authCondition = (authUser) => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
)(Timesheet)
