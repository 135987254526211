import styled from 'styled-components'

export default styled.div`
margin: 0.3rem 0;
padding: 0.3rem 0 0.5rem;

.standard-form {
  max-width: 400px;
  text-align: left;
  margin: 0 auto;

  & > label {
    font-size: 0.9rem;
    font-weight: 600;
  }
}
`
