import React from 'react'
import PropTypes from 'prop-types'

import './spinner.css'

const Spinner = ({ label }) => (
  <div className="spinner">
    <div className="lds-dual-ring" />
    <span>
      {label}
    </span>
  </div>
)

Spinner.propTypes = {
  label: PropTypes.string,
}

Spinner.defaultProps = {
  label: null,
}

export default Spinner
