import { compose } from 'recompose'
import { connect } from 'react-redux'
import withAuthorization from '../Session/withAuthorization'
import { db } from '../../firebase'
import SelectProject from './selectProject'

const mapStateToProps = (state) => ({
  projects: state.userState.projects,
  user: state.userState.user,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectProjectOnChange: (event, projects) => {
    const { uid } = ownProps.authUser
    let selectedProject = null

    Object.keys(projects).forEach((key) => {
      if (key === event.target.value) {
        selectedProject = { ...projects[key], id: key }
      }
    })

    db.setUserProject(dispatch({
      type: 'SELECT_PROJECT',
      uid,
      selectedProject,
    }))
  },
})

const authCondition = (authUser) => !!authUser

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
)(SelectProject)
